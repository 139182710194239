import React from 'react';
import { SidebarRoute,SidebarContainer, Icon, CloseIcon, SidebarWrapper,SidebarMenu,SidebarLink } from './SidebarElements'
import Footer from '../Footer';

const Sidebar = ({isOpen, toggle, isSidebar}) => {
  return (
   <SidebarContainer isOpen={isOpen} onClick={toggle} >
    	<Icon onClick={toggle}>
        <CloseIcon/>
    	</Icon>
    <SidebarWrapper >
        <SidebarMenu>
        <SidebarLink>
                <SidebarRoute to="/beta">Sign up for Smoothi Beta </SidebarRoute>
                
            </SidebarLink>
            
            <SidebarLink>
                <SidebarRoute to="/download">Download App - Coming Soon </SidebarRoute>
                
            </SidebarLink>
            <SidebarLink>
                <SidebarRoute to="/joinus">Join Us</SidebarRoute>
                </SidebarLink>
            <SidebarLink>
            <SidebarRoute to="/contact">Contact</SidebarRoute>
            </SidebarLink>
            

        </SidebarMenu>
       
    </SidebarWrapper>
    
    <Footer isSidebar={isSidebar}>
    </Footer>
   </SidebarContainer>
  )
}

export default Sidebar