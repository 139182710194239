import styled from 'styled-components'
import {FaTimes} from 'react-icons/fa'
import {Link as LinkR} from 'react-router-dom';

export const SidebarContainer = styled.aside`

    position:fixed;
    z-index:999;
    width:100%;
    height:100%;
    background: #353535;
    //display:grid;
    align-items:center;
    top:0;
    //left:0;
    right:0;
    transition:0.2s ease-in-out;
    opacity: ${({ isOpen}) => (isOpen ? '100%' : '0')};
    right:${({ isOpen}) => (isOpen ? '0': '-100%')};
    
    `

    export const CloseIcon = styled(FaTimes)`
        color: white;
    `;

    export const Icon = styled.div`
        position: absolute;
        top: 1.2rem;
        right:1.5rem;
        background:transparent;
        font-size: 1.5rem;
        cursor: pointer;
        outline: none;
    `;

    export const SidebarWrapper = styled.div`
    
    
      
        
        `
    
    export const SidebarMenu = styled.ul`

    color:#fff;
    position:absolute;
    left:0;
    
    bottom:0;
    right:0;
    padding-bottom:110px;
    margin-left:10px;

    @media screen and (max-width:500px) {
		margin-left:-10px;
	}

    @media screen and (max-width: 350px) {
		padding-bottom:130px;
	}
    
    
    
    
}
    `;
    export const SidebarLink = styled.div`
    font-family: 'Oxygen';
    font-size:25px;
    //margin-botton:90px;
  
    `;



export const SidebarRoute = styled(LinkR)`
  
  color: white;
  
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;

    color: white;
  }
`



    
    
   
