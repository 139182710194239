import React from 'react'
import BetaSection from '../components/BetaSection';




function BetaPage () {
  
    
    
    return(
      <div className="beta">
       <BetaSection> </BetaSection>
           
      </div>
    )
  };

  export default BetaPage;