import styled from 'styled-components';

export const DownloadPageContainer = styled.div`
   
position:fixed;
width:100%;
height:100%;
background: #353535;
color:white;
overflow-y:auto;
padding-bottom:100px;

    

    
`;
export const DownloadPageWrapper = styled.div`
`;

export const AppStoreWrapper = styled.div`
display:flex;
justify-content:center;
margin-top:50px;
`;

export const AppStoreContainer = styled.div`
   

width:450px;
display:flex;
justify-content:space-between;


@media screen and (max-width:500px) {
		display:grid;
        justify-content:center;
        gap:10px;
	}

    @media screen and (max-width:350px) {
		display:grid;
        justify-content:center;
        gap:10px;
	}
    
`;

export const SignUpWrapper = styled.div`
   

color:white;
display:flex;
justify-content:center;
font-family: 'Oxygen';
font-size:25px;
cursor:pointer;
    

    
`;

export const SmallText = styled.div`

margin-top:10px;
font-size: 15px;
display:flex;
justify-content:center;	
font-family: 'Oxygen', sans-serif;
background: #353535;
text-align:center;
color:white;
margin-left:30px;
margin-right:30px;



    
`;

export const SmallTextContainer = styled.div`
display:flex;
justify-content:center;	
align-content:center;
text-align:center;
width:90%;
    
`;


