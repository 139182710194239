import React from 'react'
import {HeroWrapper, DLContainer, ArrowRightF, DLText3,DLAppButton, HeroContainer,  ImageBg, HeroContent, HeroH1, HeroP } from './HeroElements'
import { SidebarRoute} from '../Sidebar/SidebarElements'
//import { useState } from 'react';

function HeroSection(props) {
	

	//const [hover, setHover] = useState(false)
    //const onHover = () => {
      //setHover(!hover)
	//}
	//const [isSubscribeOpen, setIsSubscribeOpen] = useState(false)
	
	//const subscribeToggle = () => {
		//setIsSubscribeOpen(!isSubscribeOpen)
	//}

	let ImageSrc = null
	const imageNumber = props.imageNumber
	const ImageSrc1 = 'https://smoothiimages.s3.us-west-1.amazonaws.com/image-1.png'
	const ImageSrc2 = 'https://smoothiimages.s3.us-west-1.amazonaws.com/image-2.webp'
	const ImageSrc3 = 'https://smoothiimages.s3.us-west-1.amazonaws.com/image-3.webp'
	//console.log(imageNumber)
	switch (imageNumber) {
		case 1:
			ImageSrc = ImageSrc1
			break;
		case 2:
			ImageSrc = ImageSrc2
			break;
		case 3:
			ImageSrc = ImageSrc3
			break;
		default:
			console.log("nothing")
	}
	
	return (
		<HeroWrapper>
			
		<HeroContainer>
		
				<ImageBg src={ImageSrc} />
				

			<HeroContent>
				<HeroH1>Hit Blend: Blend Hits</HeroH1>
				<HeroP>A new way to experience music!</HeroP>
				<SidebarRoute to="/beta">
			<DLAppButton>
			
				<DLContainer>
				
				<DLText3>Sign up for beta</DLText3>

				
				<ArrowRightF></ArrowRightF>
				</DLContainer>
				
			</DLAppButton>	
							
			</SidebarRoute>	
			
			</HeroContent>
			
			
		</HeroContainer>
		</HeroWrapper>
	)
}

export default HeroSection