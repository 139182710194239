import styled from "styled-components";
//import { MdKeyboardArrowRight, MdArrowForward } from 'react-icons/md'
import {ArrowRightShort} from'@styled-icons/bootstrap/ArrowRightShort'
//import {Link as LinkR} from 'react-router-dom';

export const HeroContainer = styled.div`
	//background: black;
	display: flex;
	//justify-content: center;
	align-items: center;
	height:100%;
	width:100%;
	
	padding: 0 18px;
	
	

	//position: fixed;
	//z-index: 1;
	overflow-y:auto;
	
`

export const HeroWrapper = styled.div`
//height:900px;	
//height:100%;
//overflow-y:auto;	
	
	
	
`

export const HeroBg = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	
	
	
`

export const ImageBg = styled.img`
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
	//background-color:#EFEBE5;
	min-height:100%;
	background-position: top;
	background-size: cover;
	background-attachment: scroll;
	background-repeat: no-repeat;
	position: fixed;
	top: 0;
	bottom:0;
	left: 0;
	right: 0;
	z-index: -1;	
	

`

export const HeroContent = styled.div`
	z-index: 3;
	margin-top: 330px;
	max-width: 400px;
	padding: 0px 24px;
	display: flex;
	flex-direction: column;
	margin-left:5px;
	//height:100%;
	//overflow-y:auto;

	@media screen and (max-height:500px) {
		margin-top: 250px;
	}

	@media screen and (max-height:400px) {
		margin-top: 250px;
	}
	@media screen and (max-width:500px) {
		padding: 0px 18px;
		margin-top: 400px;
		max-width: 350px;
		margin-left:-5px;
	}
`

export const HeroH1 = styled.h1`
	color: #fff;
	font-family:'Marcellus';
	font-size: 36px;
	//text-align: left;
	
	

	@media screen and (max-width:768px) {
		font-size: 32px;
	}

	@media screen and (max-width: 480px) {
		font-size: 30px;
	}
`

export const HeroP = styled.p`

	margin-top: 8px;
	color: #fff;
	font-size: 17px;
	//text-align: left;
	max-width: 600px;
	font-family: 'Oxygen', sans-serif;

	@media screen and (max-width: 768px) {
		font-size: 16.5px;
	}

	@media screen and (max-width: 480px) {
		font-size: 16px;
	}
`

export const HeroBtnWrapper = styled.div`
	margin-top: 32px;
	text-align: left;

`



export const Button = styled.button`
  color: black;
  border: 1px solid black;
  border-radius: 30px;
  font-size: 13px;
  background: white;
  padding: 9px 16px;
`

export const DLAppButton = styled.button`
width:190px;
height:52px;
color: white;

border: 2px solid black;
border-color: #D34F1D;
border-radius: 30px;
font-size: 14px;

background: #D34F1D;
//padding: 9px 16px;
font-family: 'Oxygen', sans-serif;
font-color:white;
margin-top:30px;
margin-bottom:10px;


`

export const DLText1 = styled.div`
font-weight: bold;
letter-spacing: 0.5px;
color:white;


`
export const DLText2 = styled.div`

font-size: 11px;
letter-spacing: 0.25px;
color:white;
`

export const DLText3 = styled.div`

font-size: 13px;
font-weight: bold;
letter-spacing: 0.5px;
color:white;
margin-top:10px;
`
export const DLTextWrapper = styled.div`


`

export const ArrowRightF = styled(ArrowRightShort)`

color: white;
width:40px;
margin-top:0px;

`;

export const DLContainer = styled.div`
display: flex;
flex-direction: row;
justify-content:center;

//margin-top:-4px;



`


