import { useState } from "react";
import { useParams, Link } from "react-router-dom";
import { ApolloClient, InMemoryCache, ApolloProvider, gql, useMutation } from '@apollo/client';
import { FiArrowRightCircle } from 'react-icons/fi'
import Navbar from '../components/Navbar';
import { ImageBg, SmoothiButton, SmoothiContainer, SmoothiContent, SmoothiInput } from '../components/AppComponents/appElements'; 
import { BtnCreate } from "../components/LogInSection/logInElements";

const RESET_PASSWORD = gql`
  mutation passwordReset($token: String!, $newPassword1: String!, $newPassword2: String!) {
    passwordReset(token: $token, newPassword1: $newPassword1, newPassword2: $newPassword2){
      success
      errors
    }
  }
`;

function ResetPassword({token}) {
  const [newPassword1, setNewPassword1] = useState('')
  const [newPassword2, setNewPassword2] = useState('')
  const [buttonClicked, setButtonClicked] = useState(false)
  const [password1Valid, setPassword1Valid] = useState(false)
  const [password2Valid, setPassword2Valid] = useState(false)
  const [passwordsMatch, setPasswordsMatch] = useState(false)
  const [verifyAccountFunction, { data, loading, error }] = useMutation(RESET_PASSWORD, { errorPolicy: 'all' })

  const handlePassword1Change = (event) => {
    setNewPassword1(event.target.value)
    if (event.target.value === newPassword2) {
      setPasswordsMatch(true)
    }
    else {
      setPasswordsMatch(false)
    }
    if (event.target.value.length > 7) {
      setPassword1Valid(true)
    }
    else {
      setPassword1Valid(false)
    }
  }

  const handlePassword2Change = (event) => {
    setNewPassword2(event.target.value)
    if (event.target.value === newPassword1) {
      setPasswordsMatch(true)
    }
    else {
      setPasswordsMatch(false)
    }
    if (event.target.value.length > 7) {
      setPassword2Valid(true)
    }
    else {
      setPassword2Valid(false)
    }
  }

  const handleClick = () => {
    setButtonClicked(true)
    if (password1Valid && password2Valid && passwordsMatch) {
      verifyAccountFunction({ variables: { token: token, newPassword1: newPassword1, newPassword2: newPassword2} })
    }
  }

  if (error) {
    return (
      <h4 style={{ color: "black" }}>Some error has happened.</h4>
    )
  }
  else if (loading) {
    return (
      <div>
        <h4 style={{ color: "black" }}>loading...</h4>
      </div>
    )
  }
  else if (data) {
    if (data.passwordReset.success){
      return (
        <h4 style={{ color: "black" }}>Success! Your Password as been changed.</h4>
      )
    }
    else if (!data.success && data.passwordReset.errors) {
      return (
        <h4 style={{color:"black"}}>{data.passwordReset.errors.nonFieldErrors[0].message}</h4>
      )
    }
  }
  return (
    <div>
      <SmoothiInput  style={{margin:"0.3px"}}
						type="password" 
						placeholder={'New Password'} 
						value={newPassword1} 
						onChange={handlePassword1Change} 
						onBlur={()=>{}}/>
      <br/>
      {newPassword1 && buttonClicked && !password1Valid ? 'Password must be 8 characters or longer':''}
      <SmoothiInput  style={{margin:"0.3px"}}
						type="password" 
						placeholder={'New Password Again'} 
						value={newPassword2} 
						onChange={handlePassword2Change} 
						onBlur={()=>{}}/>
      {buttonClicked && password1Valid && password2Valid && !passwordsMatch? 'Passwords must match':''}
      <br/>
      
      <SmoothiButton onClick={handleClick}>Update Password</SmoothiButton>
    </div>
  )
}

function ResetPasswordPage() {
  let params = useParams()
  const client = new ApolloClient({
    uri: process.env.REACT_APP_URI,
    cache: new InMemoryCache()
  });

  return (
    <ApolloProvider client={client}>
      <Navbar />
      <SmoothiContainer>
        <ImageBg />
        <SmoothiContent>
          <ResetPassword token={params.token}/>
          <Link to="/" style={{ textDecoration:"none" }}>
            <BtnCreate style={{ cursor: 'pointer' }}>
              Go to App <FiArrowRightCircle style={{ fontSize: "38px", margin: "7px", cursor: 'pointer' }} /> 
            </BtnCreate>
          </Link>
        </SmoothiContent>
      </SmoothiContainer>
    </ApolloProvider>
  )
}

export default ResetPasswordPage