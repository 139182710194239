import React from 'react';
import { useState } from 'react';
import {SmallText,SignUpWrapper,DownloadPageContainer} from './BetaElements'
import Navbar from "../Navbar";
import Footer from "../Footer";
import Sidebar from "../Sidebar";
import {TitleContainer} from "../componentsElements";

function BetaSection() {

const [isOpen, setIsOpen] = useState(false)
  
  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div>
      <DownloadPageContainer>
        <Sidebar isOpen={isOpen} toggle={toggle} isSidebar={true} />
        <Navbar toggle={toggle} isLanding={true} />
        <TitleContainer>Sign Up for Smoothi Beta!</TitleContainer>

        <SmallText>Get early access to test the web app.</SmallText>

        <SignUpWrapper>
          <iframe
            title="TermsFrame"
            src={`${process.env.PUBLIC_URL}/signup.html`}
            style={{ border: "white", width: "410px", height: "330px" }} />
        </SignUpWrapper>

        <SmallText style={{ marginTop: "-45px" }}>Must be 18 years or older. Only available in the United States.</SmallText>

      </DownloadPageContainer>

      <Footer> isSidebar={false} </Footer>

    </div>
  )

}

export default BetaSection;