import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import LandingPage from './pages/landingPage';
import BetaPage from './pages/betaPage';
import DownloadPage from './pages/downloadPage';
import JoinUsPage from './pages/joinUsPage';
import ContactPage from './pages/contactPage';

//import TermsPage from './pages/termsPage';
//import PrivacyPage from './pages/privacyPage';
import ActivatePage from './pages/activateAccount';
import CustomerSupportSection from './pages/customerSupportPage';
import ResetPasswordPage from './pages/resetPassword';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage/>}/>
        <Route path="/beta" element={<BetaPage/>}/>
        <Route path="/download" element={<DownloadPage/>}/>
        <Route path="/support" element={<CustomerSupportSection/>}/>
        <Route path="/joinus" element={<JoinUsPage/>}/>
        <Route path="/contact" element={<ContactPage/>}/>
        <Route path="/activate/:token" element={<ActivatePage/>}/>
        <Route path="/password-reset/:token" element={<ResetPasswordPage/>}/>
      </Routes>
    </Router>
  );
}
//<Route path="/terms" element={<TermsPage/>}/>
//<Route path="/privacy" element={<PrivacyPage/>}/>
//<Route path="/customerSupportPage" element={<CustomerSupportPage/>}/>

export default App;
