import { useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { ApolloClient, InMemoryCache, ApolloProvider, gql, useMutation } from '@apollo/client';
import { FiArrowRightCircle } from 'react-icons/fi'
import Navbar from '../components/Navbar';
import { ImageBg, SmoothiContainer, SmoothiContent } from '../components/AppComponents/appElements'; 
import { BtnCreate } from "../components/LogInSection/logInElements";

const VERIFY_ACCOUNT = gql`
  mutation VerifyAccount($token: String!) {
    verifyAccount(token: $token){
      success
      errors
    }
  }
`;

function Activation({token}) {
  const [verifyAccountFunction, { data, loading, error }] = useMutation(VERIFY_ACCOUNT, { errorPolicy: 'all' })

  useEffect(() => {
    verifyAccountFunction({ variables: { token: token } })
  },[verifyAccountFunction, token])

  if (error) {
    return (
      <h4 style={{ color: "black" }}>Some error has happened.</h4>
    )
  }
  else if (loading) {
    return (
      <div>
        <h4 style={{ color: "black" }}>loading...</h4>
      </div>
    )
  }
  else if (data) {
    if (data.verifyAccount.success){
      return (
        <h4 style={{ color: "black" }}>Success! Your Account as been verified.</h4>
      )
    }
    else if (!data.success && data.verifyAccount.errors) {
      return (
        <h4 style={{color:"black"}}>{data.verifyAccount.errors.nonFieldErrors[0].message}</h4>
      )
    }
  }
}

function ActivatePage() {
  let params = useParams();
  const client = new ApolloClient({
    uri: process.env.REACT_APP_URI,
    cache: new InMemoryCache()
  });

  return (
    <ApolloProvider client={client}>
      <Navbar />
      <SmoothiContainer>
        <ImageBg />
        <SmoothiContent>
          <Activation token={params.token}/>
          <Link to="/" style={{ textDecoration:"none" }}>
            <BtnCreate style={{ cursor: 'pointer' }}>
              Go to App <FiArrowRightCircle style={{ fontSize: "38px", margin: "7px", cursor: 'pointer' }} /> 
            </BtnCreate>
          </Link>
        </SmoothiContent>
      </SmoothiContainer>
    </ApolloProvider>
  )
}

export default ActivatePage